 <template>
  <v-menu offset-y left v-model="menu" :close-on-content-click="false">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-on="on" v-bind="attrs" class="ml-2 mt-2" small color="primary" height="38" width="35"><v-icon>mdi-plus</v-icon></v-btn>
    </template>
    <v-card>
      <v-card-text >
        <div class="d-flex flex-row">
            <v-text-field outlined dense v-model="orgName" label="Organization Name" hide-details="true"></v-text-field>
            <v-btn color="primary" :disabled="!orgName" @click="onAddOrganiztion()" class="ml-2" icon><v-icon>mdi-check</v-icon></v-btn>
            <v-btn @click="onCancelAddOrganiztion()" class="ml-2" icon><v-icon>mdi-close</v-icon></v-btn>
        </div>
        <div class="red--text"> {{ this.err }}</div>
      </v-card-text>
    </v-card>
  </v-menu>
</template>
<script>
import {getOrganizations, addOrganization} from '@/api/users'

export default {
  name: "AddOrganization",
  data: () => ({
    menu: false,
    orgName: null,
    err: null,
  }),
  methods: {
    onAddOrganiztion() {
        const _this = this
        this.err = null
        getOrganizations((orgs)=>{
            if(orgs.findIndex(org => org.name == this.orgName) < 0){
                const payload={name:_this.orgName}
                addOrganization(payload, (res)=>{
                    _this.$emit("newOrgAdded", res)
                    _this.menu = false
                }, `${_this.orgName} added successfully` )
            } else {
                _this.err = `${_this.orgName} Already exist!`
            }
        })
    },
    onCancelAddOrganiztion() {
      (this.orgName = null), (this.menu = false);
    },
  },
};
</script>
addOrganizations